import { ConnectButton } from "@rainbow-me/rainbowkit";
import { MintBRGOVSelection } from "./MintBRGOVSelection";
import { Denomination } from "./MintBRGOV";
import { useState } from "react";

function MintBRGOVPage() {
  const [denomination, setDenomination] = useState<Denomination>(
    Denomination.One
  );

  return (
    <div className="max-w-4xl mx-auto">
      <header className="border-2 border-b-0 bg-white">
        <div className="w-full">
          <a href="/mint-brgov" className="mx-auto">
            <div className="font-newtimesroman pt-10 pb-6 text-4xl sm:text-6xl text-center font-bold tracking-wider">
              Bittrees Research
            </div>
          </a>
        </div>
      </header>

      <main className="text-center bg-white border-2 border-t-0">
        <div className="flex flex-col gap-3 p-4 md:p-12 items-center">
          <div className="mx-auto flex flex-col items-center max-w-md drop-shadow-xl">
            <img
              src={`/brgov-${denomination}-preferred-cert.png`}
              width="1000px"
              height="566px"
              alt="BRGOV Certificate of Stock"
            />
          </div>
          <div className="mt-4 mb-4">
            <MintBRGOVSelection
              onChangeDenomination={(denomination: Denomination) => {
                setDenomination(denomination);
              }}
            />
          </div>
          <div>
            <ConnectButton />
          </div>
          <div className="space-y-5 mt-8 text-left mx-8 font-newtimesroman">
            <div>
              <hr className="border-gray-500" />
            </div>
            <p className="font-bold">
              Disclaimer for BRGOV Tokens:
              <br />
              <br />
              Please read this disclaimer carefully before purchasing or
              acquiring BRGOV tokens. By purchasing or acquiring BRGOV tokens,
              you acknowledge and agree to the following:
            </p>
            <p>
              <span className="font-bold">Nature of BRGOV Tokens:</span> BRGOV
              tokens are non-fungible tokens (NFTs) issued by Bittrees Research.
              These tokens represent preferred stock certificates in Bittrees
              Research and entitle the holders to limited voting rights as
              outlined in this disclaimer. BRGOV tokens do not grant any
              ownership rights or equity in Bittrees Research beyond the
              specified limited voting rights.
            </p>
            <p>
              <span className="font-bold">Voting Rights:</span> Holders of BRGOV
              tokens are granted voting rights solely for the purpose of
              approving budget proposals submitted by members of Bittrees
              Research. These voting rights are limited to the budget approval
              process and do not extend to other aspects of the organization or
              its governance.
            </p>
            <p>
              <span className="font-bold">Purchase Price:</span> BRGOV tokens
              can be acquired by exchanging 1000 BTREE tokens or 0.001 WBTC
              tokens. The purchase price is fixed and non-negotiable.
            </p>
            <p>
              <span className="font-bold">Non-Expiry:</span> BRGOV tokens do not
              have an expiration date. Once acquired, they will remain in your
              possession indefinitely.
            </p>
            <p>
              <span className="font-bold">Dividends:</span> BRGOV token holders
              should not expect any dividends or profit-sharing arrangements
              from Bittrees Research. BRGOV tokens do not confer any entitlement
              to dividends or profits generated by Bittrees Research.
            </p>
            <p>
              <span className="font-bold">Risks:</span> The purchase and
              ownership of BRGOV tokens involve certain risks. The value of
              BRGOV tokens may fluctuate based on various factors, including
              market conditions and the performance of Bittrees Research. It is
              important to carefully consider these risks and to conduct your
              own research before acquiring BRGOV tokens.
            </p>
            <p>
              <span className="font-bold">No Guarantee of Outcome:</span>{" "}
              Bittrees Research makes no guarantees or representations regarding
              the outcome, success, or future performance of the organization or
              its projects. The acquisition of BRGOV tokens does not guarantee
              any specific benefits or returns.
            </p>

            <p>
              <span className="font-bold">
                By proceeding with the acquisition of BRGOV tokens, you
                acknowledge that you have read, understood, and agreed to the
                terms and conditions set forth in this disclaimer. If you do not
                agree with any part of this disclaimer, you should refrain from
                acquiring BRGOV tokens.
              </span>
            </p>
          </div>
          <footer className="flex flex-col gap-6 mx-auto mt-4">
            <span>
              <a className="hover:text-green-700" href="/members">
                <span className="inline-block align-middle pr-1">
                  <svg
                    className="h-4 w-4 hover:text-green-700"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <circle cx="12" cy="12" r="10" />{" "}
                    <polyline points="12 8 8 12 12 16" />{" "}
                    <line x1="16" y1="12" x2="8" y2="12" />
                  </svg>
                </span>
                <span className="inline-block align-middle underline font-bold font-newtimesroman">
                  Back
                </span>
              </a>
            </span>
            <div>
              <a href="/" className="mx-auto">
                <img
                  src="/bittrees_logo_tree.png"
                  width="128px"
                  height="128px"
                  alt="Bittrees Inc logo"
                  className="grayscale max-w-xs transition duration-300 ease-in-out hover:scale-110"
                />
              </a>
            </div>
          </footer>
        </div>
      </main>
    </div>
  );
}

export default MintBRGOVPage;
